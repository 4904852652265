import * as React from "react"
import * as styles from "../styles/404.module.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Stran ni bila najdena" />
    <div className={styles.container}>
      <div className={styles.errorText}>404</div>
      <div className={styles.errorHeading}>Ups, ta stran ne obstaja.</div>
      <div className={styles.errorExplanation}>
        Prosimo, preverite spletni naslov.
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
